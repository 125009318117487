'use strict'

angular
  .module 'lpaPoiOrderTracking'
  .config ($stateProvider) ->
    $stateProvider
      .state 'poiOrderTracking',
        url: '/poi-order-tracking/{poi_order_id}',
        templateUrl: 'lpa/lpa-poi-order-tracking/views/lpa-poi-order-tracking.tpl.html'
        controller: 'PoiOrderTrackingCtrl'
        controllerAs: 'poiOrderTracking'
        onEnter: [
          'MundoSocket'
          (MundoSocket) ->
            MundoSocket.toggleUnitStatusConsumer true
            MundoSocket.toggleUnitEventConsumer true
            MundoSocket.toggleTenantEventConsumer true
        ]
        onExit: [
          'MundoSocket'
          (MundoSocket) ->
            MundoSocket.toggleUnitStatusConsumer false
            MundoSocket.toggleUnitEventConsumer false
            MundoSocket.toggleTenantEventConsumer false
        ]
        data:
          navigation: false
